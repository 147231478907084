<template>
  <div class="custom-select-body">
    <div
      class="custom-selector"
      @blur="open = false"
    >
      <button
        class="selected"
        :class="{ open: open }"
        data-testid="customDropdownSortByButton"
        @click="open = !open"
      >
        <span class="span-hide">Sort By: </span><span class="selected-span">
          {{ selected }}
        </span>
        <img
          class="angle-up"
          :class="{ rotate: open }"
          src="../../assets/svg/chevron-down-black.svg"
          alt=""
        >
      </button>
      <div
        class="items"
        :class="{ selectHide: !open }"
        data-testid="customDropDownItems"
      >
        <ul>
          <li
            v-for="(option, i) of options"
            :key="i"
          >
            <button
              class="list-btn"
              :class=" {'activeSelect' : activeSelectClass(option.title)}"
              @click="selectChange(option)"
            >
              {{ option.title }}
            </button>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SortByDropdown',
  props: {
    defaultSelected: {
      type: String,
      required: false,
      default: null
    }
  },
  data () {
    return {
      options: [
        { title: 'Newest to Oldest', key: 'newest' },
        { title: 'Title - A to Z', key: 'alphabetically' }
      ],
      selected: this.defaultSelected ? 'Newest to Oldest' : null,
      open: false
    }
  },
  methods: {
    // On changing sorting type
    selectChange (option) {
      this.selected = option.title
      this.$emit('sortBy', option)
      this.open = false
    },
    activeSelectClass (title) {
      return title === this.selected
    }
  }
}
</script>
<style lang="scss" scoped>

.custom-select-body {
  width: 220.4px;
  margin: 0;
  font-size: 14px;

  .custom-selector {
    display: inline-flex;
    position: relative;
    width: 100%;
    line-height: 24px;

    .selected {
      text-align: left;
      width: 100%;
      background-color: $grey-1;
      border: 1px solid $grey-2;
      color: $black;
      padding: 7px 16px 7px 16px;
      cursor: pointer;
      font-size: 0.875rem;

      &:focus {
        border: 1px solid $black;
        outline: 0;
      }

      &:hover {
        border: 1px solid $black;
      }

      .open {
        border: 1px solid $grey-2;
      }
    }

    &:focus {
      border-color: $grey-2;
      outline: 0;
      box-shadow: unset;
    }

    .items {
      border: 1px solid $grey-2;
      position: absolute;
      top: 130%;
      background-color: $grey-1;
      left: 0;
      right: 0;
      z-index: 1;

      ul > li .list-btn {
        border: none;
        background: none;
        outline: none;
        padding: 7px 16px 7px 16px;
        cursor: pointer;
        width: 100%;
        color: $black;
        text-align: left;
        border-bottom: 1px solid $grey-2;
      }

      ul > li .list-btn:focus {
        box-shadow: 0 0 2px 2px  $grey-2;
      }

      ul > li:last-child .list-btn {
        border-bottom: 1px solid transparent;
      }

      ul > li .list-btn:hover {
        text-decoration: underline;
      }

      /* for little arrow at the top of itme list  */
      &::after {
        content: "";
        width: 0;
        height: 0;
        position: absolute;
        bottom: 100%;
        right: 51%;
        border-width: 0 8px 8px 8px;
        border-style: solid;
        border-color: $grey-1 transparent;
      }

      &::before {
        content: "";
        width: 0;
        height: 0;
        position: absolute;
        bottom: 100%;
        right: 50%;
        border-width: 0 10px 10px 10px;
        border-style: solid;
        border-color: rgba(0, 0, 0, 0.1) transparent;
      }
    }
  }

  .selectHide {
    display: none;
  }

  .activeSelect {
    font-weight: 600;
    pointer-events: none;
  }

  .selected-span {
    font-weight: 600;
    padding-right: 8px;
  }

  .span-hide {
    display: inline;
  }

  .angle-up {
    position: absolute;
    right: 12px;
    top: 17px;
    transform: rotate(0deg);
    transition: ease-out 0.3s;

    &.rotate {
      transform: rotate(180deg);
      transition: ease-out 0.3s;
    }
  }
}

@media only screen and (min-width: $breakpoint-sm-min) {
  .custom-select-body {
    width: 165px;

    .span-hide {
      display: none;
    }
  }
}

@media only screen and (min-width: $breakpoint-md-min) {
  .custom-select-body {
    width: 220.4px;

    .span-hide {
      display: inline;
    }
  }
}
</style>

<template>
  <div class="pagination-custom-select-body">
    <div
      class="custom-select"
      @blur="open = false"
    >
      <div class="pagination-custom d-flex justify-content-center align-items-center">
        <button
          class="prev-page nav-arrow d-flex justify-content-center align-items-center"
          :class="{ 'arrowDisable' : selected === 1 }"
          data-testid="paginationPreviousButton"
          @click="prevPage"
        >
          <img
            class="chevron chevron-left"
            src="../../assets/svg/chevron-left-icon.svg"
            alt="left page arrow"
          >
        </button>
        <div
          class="selected"
          :class="{ open: open }"
          data-testid="paginationStatusText"
          @click="open = !open"
        >
          page {{ selected }} of {{ totalPages }}
        </div>
        <button
          class="next-page nav-arrow d-flex justify-content-center align-items-center"
          :class="{ 'arrowDisable' : selected === totalPages}"
          data-testid="paginationNextButton"
          @click="nextPage"
        >
          <img
            class="chevron chevron-right"
            src="../../assets/svg/chevron-right-icon.svg"
            alt="next page arrow"
          >
        </button>
      </div>
      <div
        class="items"
        :class="{ 'd-none': !open, 'bottom-to-top': direction === 'top' }"
        data-testid="paginationPageList"
      >
        <div class="inner-select-items">
          <div
            v-for="index in totalPages"
            :key="index"
            :class=" {'activeSelect' : activeSelectClass( index)}"
            data-testid="paginationPageListItem"
            @click="selectChange(index)"
          >
            page {{ index }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PaginationCustom',
  props: {
    currentPage: {
      type: Number,
      required: false,
      default: 1
    },
    totalPages: {
      type: Number,
      required: true
    },
    direction: {
      type: String,
      required: false,
      default: 'bottom',
      validator: (val) => ['top', 'bottom'].includes(val)
    }
  },
  data () {
    return {
      selected: this.currentPage,
      open: false
    }
  },
  methods: {
    selectChange (selectedIndex) {
      this.selected = selectedIndex
      this.$emit('change', this.selected)
      this.open = false
    },
    activeSelectClass (title) {
      return title === this.selected
    },
    nextPage: function () {
      if (this.selected < this.totalPages) {
        this.selected++
        this.$emit('change', this.selected)
      }
    },
    prevPage: function () {
      if (this.selected > 1) {
        this.selected--
        this.$emit('change', this.selected)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.pagination-custom {
  max-width: 184px;
  width: 100%;
  position: relative;
  display: flex;

  .nav-arrow {
    max-width: $space-x-lg;
    height: $space-x-lg;
    background: #f2f2f2;
    border: 1px solid $grey-2;
    flex: 1;
    z-index: 1;
    cursor: pointer;

    &:focus {
      border: 1px solid $black;
      outline: 0;
    }

    &:hover {
      border: 1px solid $black;
    }
  }
}

.pagination-custom-select-body {
  .custom-select {
    display: inline-flex;
    position: relative;
    width: 100%;
    height: $space-x-lg;
    line-height: $space-md;
    padding: 0;
    border: unset;
    border-radius: unset;
    background: none;

    .arrowDisable {
      cursor: default;

      .chevron {
        opacity: 0.54;
      }

      &:focus,
      &:hover {
        border-color: $grey-2;
      }
    }

    &:focus {
      border-color: $grey-2;
      outline: 0;
      box-shadow: unset;
    }

    .selected {
      text-align: center;
      width: 100%;
      color: #000;
      cursor: pointer;
      font-size: 0.875em;
      flex: 1;
      text-decoration: underline;

      &:hover {
        color: $grey-3;
      }
    }

    .items {
      z-index: 99;
      border: 1px solid $grey-2;
      position: absolute;
      top: 130%;
      background-color: $grey-1;
      left: 0;
      right: 0;

      &.bottom-to-top {
        top: auto;
        bottom: 130%;

        &::after {
          content: "";
          width: 0;
          height: 0;
          position: absolute;
          bottom: -8px;
          left: 50%;
          border-width: $space-x-sm $space-x-sm 0 $space-x-sm;
          border-style: solid;
          border-color: $grey-1 transparent;
          -webkit-transform: translate(-51%, 0);
          transform: translate(-51%, 0);
        }

        &::before {
          content: "";
          width: 0;
          height: 0;
          position: absolute;
          bottom: -10px;
          right: 50%;
          border-width: 10px 10px 0 10px;
          border-style: solid;
          border-color: rgba(0, 0, 0, 0.1) transparent;
          -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
        }
      }

      .inner-select-items {
        max-height: 399px;
        overflow-y: auto;

        div {
          position: relative;
          width: auto;
          height: $space-x-lg;
          color: #000;
          cursor: pointer;
          text-align: left;
          border-bottom: 1px solid $grey-2;
          font-size: 0.875em;
          padding: $space-x-sm 0;
          display: flex;
          align-items: center;
          margin: 0 $space-sm;

          &:last-child {
            border-bottom: 1px solid transparent;
          }

          &:hover {
            text-decoration: underline;
          }
        }

        div.activeSelect {
          &::after {
            content: "";
            position: absolute;
            width: 12px;
            height: 12px;
            background: url(../../assets/svg/tick-icon.svg) no-repeat center center;
            right: 0;
          }
        }
      }

      &::after {
        content: "";
        width: 0;
        height: 0;
        position: absolute;
        bottom: 100%;
        left: 50%;
        border-width: 0 $space-x-sm $space-x-sm $space-x-sm;
        border-style: solid;
        border-color: $grey-1 transparent;
        -webkit-transform: translate(-51%, 0);
        transform: translate(-51%, 0);
      }

      &::before {
        content: "";
        width: 0;
        height: 0;
        position: absolute;
        bottom: 100%;
        left: 50%;
        border-width: 0 10px 10px 10px;
        border-style: solid;
        border-color: rgba(0, 0, 0, 0.1) transparent;
        -webkit-transform: translate(-50%, 0);
        transform: translate(-50%, 0);
      }
    }
  }

  .activeSelect {
    font-weight: 600;
    pointer-events: none;
  }

  .span-hide {
    display: inline;
  }
}

@media only screen and (min-width: $breakpoint-sm-min) {
  .pagination-custom-select-body {
    width: 184px;

    .span-hide {
      display: none;
    }
  }
}

@media only screen and (min-width: $breakpoint-lg-min) {
  .span-hide {
    display: inline;
  }
}

</style>
